<template>
    <div class="w3-container" style="padding-top: 40px;padding-bottom:16px; margin-top:40px" v-bind:style="'height:'+  (screenHeight-40) +'px;'">
        <Toolbar  
            :click-add="showAdd"
            :click-edit="showEdit"
            :click-delete="showDelete"
            :click-refresh="showRefresh"
            :set-filter="loadDataSearch"
            :status-print="false"
            :data   = "recs"
            :fields = "json_fields"
            type    = "xls"
            name    = "Kendaraan.xls"
        />
        <div class="w3-container" style="padding: 0px;">
            <div id="wrapper">
                <div id="content">
                    <div class="w3-hide-small w3-hide-medium" id="boundary">
                        <table id="header" cellpadding="4" cellspacing="0" border="0" style="width:100%;">
                            <thead>
                                <tr class="w3-theme-l2">
                                    <th width="50px">No. </th>
                                    <th v-for="fl in filterByShow" :width="fl.width" :key="fl.name" class="">{{fl.name}}</th>
                                    <th width="1px"></th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-165) +'px;'">
                        <table class="w3-table w3-table-all">
                            <tr v-for="(dt, index) in recs" :key="dt.k_id" class="w3-hover-pale-blue w3-small cursor" :class="index == selectedRow ?'w3-pale-blue':''">
                                <td class="w3-hide-large" v-on:click="setKlikRow(index, dt)">
                                    <div class="w3-col s4 m2 w3-theme-l5">
                                        <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>ID
                                    </div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.k_id}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Name</div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.k_name}} / {{dt.k_urut}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Keterangan</div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.k_keterangan}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Status</div>
                                    <div class="w3-col s8 m10 w3-theme-l4" v-html="setStatus(dt.k_stat)">
                                    </div>
                                </td>
                                <td width="50px" class="w3-hide-small w3-hide-medium w3-border-right">
                                    <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>&nbsp;{{dt.idx}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :width="findObjFind('width','k_id')" class="w3-hide-small w3-hide-medium w3-border-right">
                                    {{dt.k_id}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','k_name')" class="w3-hide-small w3-hide-medium w3-border-right">
                                    {{dt.k_name}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','k_keterangan')" class="w3-hide-small w3-hide-medium w3-border-right">
                                    {{dt.k_keterangan}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" style="padding: 3px 0px 0px 10px;" :style="'width:' + findObjFind('width','k_stat')" class="w3-hide-small w3-hide-medium">
                                    <span v-html="setStatus(dt.k_stat)"></span>
                                </td>
                            </tr>                                            
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- pagging -->
        <div style="height: 35px;bottom: 25px;">
            <div class="w3-bar w3-center w3-theme" style="height: 38px;position: absolute;bottom: 0;left: 0px;">
                <a :class="pages.halaman == 1 ?'dsb':''" class="w3-bar-item w3-button" @click="first()" href="#">First</a>
                <a :class="pages.halaman == 1 ?'dsb':''" class="w3-bar-item w3-button" @click="prev()" href="#">«</a>
                <a class="w3-bar-item w3-button w3-theme-l4" href="#">Page {{pages.halaman}} sd {{pages.ttlhalaman}}</a>
                <a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="w3-bar-item w3-button" @click="next()" href="#">»</a>
                <a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="w3-bar-item w3-button" @click="last()" href="#">Last</a>
                <a class="w3-bar-item w3-button w3-border-left w3-right" href="#">Rec {{pages.ttlrec}}</a>
            </div>
        </div>
        <div id="modal-form" class="w3-modal scrl-y">
            <div class="w3-modal-content w3-animate-zoom w3-card-4 w3-round-large" style="max-width:600px;top: -40px;">
            <header class="w3-container w3-theme-d3 round-top-large"> 
                <span onclick="document.getElementById('modal-form').style.display='none'" 
                class="w3-button w3-display-topright w3-round-large">&times;</span>
                <h5><i class="fa fa-windows"></i>&nbsp; Add / Edit data</h5>
            </header>
            <div class="w3-container">
                <form class="w3-container" id="frmKendaraan" v-on:submit.prevent="saveData" autocomplete="off">
                    <div class="w3-row" style="margin-top:5px;">
                        <div class="w3-col l3 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                            <label for="k_id">{{findObjFind('name','k_id')}} :</label>
                        </div>
                        <div class="w3-col l9 m9 s12">
                            <input ref="k_id" readonly type="text" class="w3-input w3-small w3-round-large" id="k_id" :placeholder="findObjFind('name','k_id')" v-model="rec.k_id">
                        </div>
                    </div>
                    <div class="w3-row" style="margin-top:5px;">
                        <div class="w3-col l3 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                            <label for="k_name">{{findObjFind('name','k_name')}} :</label>
                        </div>
                        <div class="w3-col l9 m9 s12">
                            <input ref="k_name" required type="text" class="w3-input w3-small w3-round-large" id="k_name" :placeholder="findObjFind('name','k_name')" v-model="rec.k_name">
                        </div>
                    </div>
                    <div class="w3-row" style="margin-top:5px;">
                        <div class="w3-col l3 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                            <label for="k_keterangan">{{findObjFind('name','k_keterangan')}} :</label>
                        </div>
                        <div class="w3-col l9 m9 s12">
                            <input ref="k_keterangan" type="text" class="w3-input w3-small w3-round-large" id="k_keterangan" :placeholder="findObjFind('name','k_keterangan')" v-model="rec.k_keterangan">
                        </div>
                    </div>
                    <div class="w3-row" style="margin-top:5px;">
                        <div class="w3-col l3 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                            <label for="k_stat">&nbsp;</label>
                        </div>
                        <div class="w3-col l9 m9 s12">
                            <label class="container w3-small">{{findObjFind('name','k_stat')}}
                                <input v-model="rec.k_stat" type="checkbox" true-value="T" false-value="F">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </form>
            </div>
            <footer class="w3-container w3-theme-d1"  style="margin-top: 15px;">
                <h6 class="w3-right">
                <button form="frmKendaraan" class="w3-small w3-btn w3-ripple w3-white w3-round" type="submit"><i class="fa fa-save"></i> Save</button>
                <button type="button" class="w3-small w3-btn w3-ripple w3-red w3-round" onclick="document.getElementById('modal-form').style.display='none'"><i class="fa fa-close"></i> Close</button>
                </h6>
            </footer>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Config from '@/conf/Config.js';
import axios from "axios";
import "vue-search-select/dist/VueSearchSelect.css";
import Toolbar from "@/components/Toolbar.vue";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Kendaraan',
    components: {
        Toolbar
    },
    data() {
        return {
            screenHeight : 0,
            selectedRow : -1,
            DatePickerFormat: 'YYYY-MM-DD',
            rec: {},
            recs: [],
            pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, limit:Config.perpage, search:''},
            filters : {
            	operator : 'AND',
                fields :[
                        {fieldFind : '', key : 'k_id', type: 'text', name : 'ID', filter : true, width : '150px', grid : true},
                        {fieldFind : '', key : 'k_name', type: 'text', name : 'Name', filter : true, width : '150px', grid : true},
                        {fieldFind : '', key : 'k_keterangan', type: 'text', name : 'Keterangan', filter : true, width : 'auto', grid : true},
                        {fieldFind:'', key : 'k_stat', type: 'select', name : 'Status', filter : true, width : '80px', grid : true, valueFind: [{key:'T',label:'Aktif'},{key:'F',label:'Tidak Aktif'}]},
                ],
                find : []   
            },
            /**Untuk transfer excel*/
            json_fields : {
                'ID' : 'k_id',
                'NAME' : 'k_name',
                'KETERANGAN' : 'k_keterangan'
            },
        }
    },
    methods : {
        findObjFind(key, val){
            return this.findObj(this.filters.fields,'key',val)[key];
        },
        findField(val){
            return this.findObj(this.fieldRules, 'field',val);
        },
        findObj(obj, key, value) {
            let ketemu = 0;
            for (var pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        first(){
            let self = this;
            self.pages.halaman = 1;
            self.loadData();
        },
        prev(){
            let self = this;
            self.pages.halaman = self.pages.halaman - 1;
            self.loadData();
        },
        next(){
            let self = this;
            self.pages.halaman = self.pages.halaman + 1;
            self.loadData();
        },
        last(){
            let self = this;
            self.pages.halaman = self.pages.ttlhalaman;
            self.loadData();
        },
        setKlikRow(index, dt){
            let self = this;
            self.selectedRow = index;
            self.rec = Object.assign({}, dt);
        },
        loadData(){
            this.screenHeight = window.innerHeight;
            let self = this;
            self.selectedRow = -1;
            self.rec = {};
            let params = {
                pfunction:'show',
                limit: self.pages.limit,
                page: self.pages.halaman,
                search: self.pages.search
            }
            axios.post("master/ApiKendaraan.php",params).then(function (response) {
                let idx = (self.pages.limit*self.pages.halaman)-self.pages.limit+1;
                self.pages.ttlrec = parseFloat(response.data.ttlrec);
                self.pages.ttlhalaman = Math.ceil(parseFloat(response.data.ttlrec)/self.pages.limit);
                response.data.rec.map(function(val){
                    val['idx'] = idx;
                    idx++;
                });
                self.recs = response.data.rec;
            });
        },
        showRefresh(){
            this.pages.search = "";
            this.loadData();
        },
        loadDataSearch(filter){
            this.pages.search = filter;
            this.loadData();
        },
        async showAdd() {
            let self = this;
            self.stsAdd = true;
            let key = await axios.post("admin/ApiGetId.php",{
                pfunction : 'getId',
                vc_prefix : 'MTR',
                vc_table : 'tb_kendaraan'
            });
            self.rec = {
                k_id : key.data,
                k_name : '',
                k_keterangan : '',
                k_stat : 'T'
            };
            document.getElementById("modal-form").style.display = "block";
            setTimeout(function(){
                document.getElementById('k_name').focus();
            },500);
        },
        showEdit(){
            let self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('pilih kendaraan.!');
                return false;
            }else{
                this.stsAdd = false;
                document.getElementById('modal-form').style.display='block';
                setTimeout(function(){
                    document.getElementById('k_name').focus();
                },500);
            }
        },
        saveData(event){
            var self = this;
            if (self.stsAdd == true) {
                self.rec.pfunction = "add";
            }else{
                self.rec.pfunction = "edit";
            }
            self.rec['userentry'] = localStorage.username;
            axios.post("master/ApiKendaraan.php",self.rec).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    if (self.stsAdd) {
                        self.recs.splice(0, 0, self.rec);
                    }else{
                        self.recs[self.selectedRow] = self.rec;
                    }
                    document.getElementById('modal-form').style.display='none';
                    self.selectedRow = -1;
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        showDelete() {
            var self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('pilih kendaraan.!');
                return false;
            }else{
                swal(
                    {
                        title: "Yakin Ingin Menghapus Data ..!",
                        text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Ya, Hapus Data",
                        cancelButtonText: "Tidak, Batal Hapus",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                    },
                    function(isConfirm) {
                        if (isConfirm) {
                            var vparams = { 
                                k_id : self.rec.k_id,
                                pfunction : "delete"
                            };
                            axios.post("master/ApiKendaraan.php",vparams).then(function (response) {
                                if (response.data.success == true) {
                                    swal({title: "Hapus..!",text: "Data Sudah terhapus",timer: 1000,type: "success"});
                                    self.$delete(self.recs, self.selectedRow);
                                }else{
                                    swal({title: "Delete",text: "Gagal delete data",type: "error",html: true});
                                }
                            });
                        }
                    }
                );
            }
        },
        setStatus(dt) {
            if (dt === "T") {
                return "<div class='w3-green w3-center w3-round' style='width:50px;margin-top:5px;'>Active</div>";
            } else {
                return "<div class='w3-red w3-center w3-round' style='width:50px;margin-top:5px;'>In Active<div>";
            }
        },
        setInit() {
            let self = this;
            axios.post("master/ApiKendaraan.php",{
                pfunction : 'setcustomer'
            }).then(function (response) {
                self.customers = response.data.rec;
            });
        }
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
        pNumber(num) {
            var x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        }
    },
    watch: {
        // 'moveCenter': function(){
        //     console.log("OK");
        // }
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;                
            });
        }
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        // this.setInit();
        this.loadData();
    }
};
</script>